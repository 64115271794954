@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Garet";
  src:
    url("/fonts/Garet-Book.woff2") format("woff2"),
    url("/fonts/Garet-Book.woff") format("woff"),
    url("/fonts/Garet-Book.ttf") format("truetype"),
    url("/fonts/Garet-Book.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Garet";
  src:
    url("/fonts/Garet-Regular.woff2") format("woff2"),
    url("/fonts/Garet-Regular.woff") format("woff"),
    url("/fonts/Garet-Regular.ttf") format("truetype"),
    url("/fonts/Garet-Regular.otf") format("opentype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Garet";
  src:
    url("/fonts/Garet-Medium.woff2") format("woff2"),
    url("/fonts/Garet-Medium.woff") format("woff"),
    url("/fonts/Garet-Medium.ttf") format("truetype"),
    url("/fonts/Garet-Medium.otf") format("opentype");
  font-weight: 900;
  font-display: swap;
}

:root {
  /* default */
  --nav-height: 4rem;
  --top-banner-height: 0px;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  @apply selection:bg-black selection:text-white;
}

.bg-black {
  @apply selection:bg-white selection:text-black;
}

.bg-white {
  @apply selection:bg-black selection:text-white;
}

label[for]:not(.disabled) {
  @apply cursor-pointer;
}

.-z-1 {
  z-index: -1;
}

.text-with-links a {
  @apply underline;
  @apply mouse-hover:no-underline;
  @apply font-semibold;
}

.overlay {
  @apply absolute top-0 left-0 w-full h-full;
  @apply object-cover;
}

.panel-divider {
  @apply relative;
}

.panel-divider:after {
  content: "";
  @apply absolute;
  @apply top-full;
  @apply left-0;
  @apply w-full;
  @apply h-px;
  background: linear-gradient(
    to right,
    #fff 0%,
    #fff 22%,
    #dcdcdc 22%,
    #dcdcdc 78%,
    #fff 78%,
    #fff 100%
  );
  z-index: 2;
}

@media (min-width: 640px) {
  .panel-divider:after {
    @apply top-1/2;
    @apply left-full;
    @apply w-px;
    @apply h-full;
    @apply -translate-y-1/2;
    background: linear-gradient(
      to bottom,
      #fff 0%,
      #fff 22%,
      #dcdcdc 22%,
      #dcdcdc 78%,
      #fff 78%,
      #fff 100%
    );
  }
}

.draw-line {
  @apply relative;
}

.draw-line:after {
  content: "";
  @apply absolute;
  @apply top-full;
  @apply left-0;
  @apply w-full;
  @apply h-px;
  background-color: currentColor;
  transition: transform 0.5s ease;
  transform: scaleX(0);
  transform-origin: bottom right;
}

.draw-line:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

ul:not(.list-none) li {
  @apply relative;
}

ul:not(.list-none) li:before {
  content: "";
  @apply absolute;
  top: 0.8rem;
  left: -1rem;
  width: 3px;
  height: 3px;
  background-color: currentColor;
  @apply rounded-full;
}

ul.list-inside li {
  padding-left: 1rem;
}

ul.list-inside li:before {
  @apply left-0;
  top: 0.8rem;
}

.accordion {
  @apply relative;
  @apply overflow-hidden;
  max-height: 355px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.accordion:not(.accordion-open).accordion-1\/2 {
  max-height: 155px;
}

.accordion:not(.accordion-open).accordion-2-line {
  max-height: 85px;
}

.accordion:not(.accordion-open).accordion-0 {
  @apply max-h-0;
}

.accordion:not(.accordion-open).accordion-concepts {
  max-height: 750px;
}

.accordion:not(.accordion-solid):after {
  content: "";
  @apply absolute;
  @apply bottom-0;
  @apply left-0;
  @apply w-full;
  @apply pointer-events-none;
  height: 60px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 70%);
  z-index: 1;
  transition: opacity 0.5s ease;
}

.accordion-open {
  max-height: 1300px;
  transition: max-height 1s ease-in-out;
}

.accordion-open.accordion-lg {
  max-height: 1840px;
}

.accordion-open.accordion-xl {
  max-height: 8000px;
}

@media (min-width: 768px) {
  .accordion-open.accordion-lg {
    max-height: 1200px;
  }

  .accordion-open.accordion-xl {
    max-height: 5600px;
  }
}

@media (min-width: 1024px) {
  .accordion-open.accordion-xl {
    max-height: 3300px;
  }
}

.accordion-open:not(.accordion-solid):after {
  @apply opacity-0;
}

@media (min-width: 768px) {
  .accordion.md\:no-accordion {
    overflow: unset;
    max-height: unset !important;
  }

  .accordion.md\:no-accordion:after {
    @apply hidden;
  }
}

.map-pin {
  transform: translate(-50%, -50%) translateY(-20px);
}

.burger {
  @apply relative;
  width: 30px;
  height: 25px;
}

.burger .line {
  @apply absolute;
  width: 30px;
  height: 4px;
  transform: translate3d(0, 0, 0, 0);
  transition:
    transform 0.5s ease-out,
    top 0.3s ease,
    left 0.3s ease;
}

.burger .line-1 {
  @apply top-0;
  @apply left-0;
  @apply bg-black;
  @apply origin-top-left;
}

.burger .line-2 {
  top: 11px;
  @apply left-0;
  @apply origin-top-left;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  border-radius: 0 4px 4px 0;
}

.burger .line-2 .dot {
  @apply absolute;
  @apply top-0;
  @apply right-0;
  @apply bg-black;
  @apply rounded-full;
  @apply transition-all;
  @apply duration-300;
  @apply ease-linear;
  width: 4px;
  height: 4px;
  z-index: 1;
}

.burger .line-3 {
  top: 22px;
  @apply left-0;
  @apply bg-black;
  @apply origin-top-left;
}

.burger.active .line {
  height: 3px;
  width: 35px;
  transition:
    transform 0.3s ease-out,
    top 0.5s ease,
    left 0.5s ease;
}

.burger.active .line-1 {
  @apply top-1/2;
  @apply left-1/2;
  transform: rotate(45deg) translate(-50%, -50%);
}

.burger.active .line-2 {
  @apply top-1/2;
  @apply left-1/2;
  transform: rotate(-45deg) translate(-50%, -50%);
}

.burger.active .line-2 .dot {
  width: 3px;
  height: 3px;
}

.burger.active .line-3 {
  @apply top-1/2;
  @apply left-1/2;
  transform: rotate(-45deg) translate(-50%, -50%) translateX(-9px);
  width: 17px;
}

/* Select input */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(/img/icons/arrow_down.svg);
  background-repeat: no-repeat;
  background-position: center right 25px;
  background-size: 11px 8px;
}

select:invalid {
  color: #f00 !important;
}

.tiny-border {
  @apply relative;
}

.tiny-border:before,
.tiny-border:after {
  content: "";
  @apply absolute;
  @apply top-1/2;
  @apply w-px;
  @apply -translate-y-1/2;
  height: 37px;
  background-color: #dcdcdc;
}

.tiny-border:before {
  @apply left-0;
}

.tiny-border:after {
  @apply right-0;
}

.toggle {
  @apply relative;
  @apply block;
  width: 74px;
  height: 44px;
  background-color: #f0f0f0;
  border-radius: 25px;
}

.toggle input {
  @apply hidden;
}

.toggle-thumb {
  @apply absolute;
  top: 4px;
  left: 6px;
  width: 32px;
  height: 32px;
  background-color: #c4c4c4;
  border-radius: 50px;
  @apply inline-flex;
  @apply items-center;
  @apply justify-center;
  @apply transition-all;
  @apply duration-200;
  @apply ease-linear;
}

.toggle-thumb svg {
  @apply opacity-0;
  @apply transition-opacity;
  @apply duration-200;
  @apply ease-linear;
}

input:checked + .toggle-thumb {
  @apply bg-black;
  left: 33px;
}

input:checked + .toggle-thumb svg {
  @apply opacity-100;
}

.rounded-checkbox input {
  @apply hidden;
}

.rounded-checkbox {
  @apply relative;
  @apply rounded-full;
  @apply inline-flex;
  @apply items-center;
  @apply justify-center;
  top: 5px;
  width: 32px;
  height: 32px;
  background-color: #e7e7e7;
}

.rounded-checkbox .inner {
  @apply rounded-full;
  @apply bg-black;
  @apply opacity-0;
  @apply transition-opacity;
  @apply duration-300;
  @apply ease-linear;
  width: 20px;
  height: 20px;
}

.rounded-checkbox input:checked + .inner {
  @apply opacity-100;
}

.rounded-checkbox.disabled input:checked + .inner {
  @apply opacity-100;
  background-color: rgba(0, 0, 0, 0.15);
}

/* Checkbox */
.checkbox {
  @apply relative;
  @apply block;
  width: 35px;
  height: 35px;
  background-color: #f0f0f0;
  border-radius: 10px;
}

.checkbox input {
  @apply hidden;
}

.checkbox .inner {
  @apply relative;
  @apply inline-flex;
  @apply bg-black;
  @apply opacity-0;
  @apply transition-opacity;
  @apply duration-200;
  @apply ease-linear;
  width: 25px;
  height: 25px;
  border-radius: 6px;
  top: 3px;
  left: 3px;
}

.checkbox svg {
  @apply m-auto;
}

input:checked + .inner {
  @apply opacity-100;
}

.line-gradient-gray {
  @apply w-full;
  @apply h-px;
  background: linear-gradient(
    to right,
    rgba(220, 220, 220, 0) 0%,
    rgba(220, 220, 220, 1) 50%,
    rgba(220, 220, 220, 0) 100%
  );
}

.video-overlay:after {
  content: "";
  @apply absolute;
  @apply bottom-0;
  @apply left-0;
  @apply z-10;
  @apply w-full;
  @apply pointer-events-none;
  height: 60%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.75) 48%,
    rgba(0, 0, 0, 0) 96%
  );
}

@media (min-width: 768px) {
  .video-overlay:after {
    @apply h-full;
    width: MAX(500px, 60%);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.6) 69.86%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.divided:after {
  content: "";
  @apply absolute;
  background: linear-gradient(
    to bottom,
    #fff 0%,
    #fff 22.5%,
    #dcdcdc 22.5%,
    #dcdcdc 77.5%,
    #fff 77.5%,
    #fff 100%
  );
  @apply top-1/2;
  @apply left-1/2;
  @apply w-px;
  @apply h-full;
  @apply -translate-y-1/2;
  @apply pointer-events-none;
}

.bg-split-vertical {
  background: linear-gradient(
    to bottom,
    #fff 0%,
    #fff 50%,
    #000 50%,
    #000 100%
  );
}

.bg-split-vertical-reverse {
  background: linear-gradient(to top, #fff 0%, #fff 50%, #000 50%, #000 100%);
}

.rotating-circle-left {
  animation: rotateLeft 0.8s ease forwards;
}

.rotating-circle-right {
  animation: rotateRight 0.8s ease forwards;
}

@keyframes rotateLeft {
  0% {
    transform: none;
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes rotateRight {
  0% {
    transform: none;
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade-in {
  animation: fadeIn 0.8s ease-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

/* Image Comparison Slider */
.comparison {
  --default-handle-width: 100px;
  --divider-width: 2px;
  --divider-color: #fff;
  --transition-time: 2s;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s linear !important;
}

.comparison * {
  will-change: transform;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s linear !important;
}

.comparison-handle {
  @apply cursor-pointer;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

.comparison:focus {
  /*--default-handle-opacity: 0;*/
  @apply outline-none;
}

.handle-container {
}

.job-item ul,
.job-item ol {
  @apply pl-4;
}

@media (max-width: 767px) {
  .swiper-slide.slide-scale:not(.swiper-slide-active) > div {
    transform: scale(0.9);
    /*color: rgba(0, 0, 0, 0);*/
  }
}

@keyframes circlePulse {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.98);
  }

  80% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.02);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.02);
  }
}

.benefits-circle {
  animation-name: circlePulse;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 2.5s;

  opacity: 0;
}

.benefits-circle.circle-1 {
}

.benefits-circle.circle-2 {
  animation-delay: 0.5s;
}

.benefits-circle.circle-3 {
  animation-delay: 1s;
}

@keyframes float {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(50%) translateY(-15px);
  }
}

@keyframes floatShadow {
  0% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 25px 15px 0 rgba(0, 0, 0, 0.05);
  }
}

.benefits-tile {
  animation-name: float;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 2.5s;

  transform: translateX(50%);
}

.benefits-tile-inner {
  animation-name: floatShadow;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 2.5s;

  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
}

.benefits-tile-3,
.benefits-tile-3 .benefits-tile-inner {
  animation-delay: 1.1s;
}

.benefits-tile-2,
.benefits-tile-2 .benefits-tile-inner {
}

.benefits-tile-1,
.benefits-tile-1 .benefits-tile-inner {
  animation-delay: 1.9s;
}

.drop-shadow-christmas-star {
  filter: drop-shadow(0 4px 4px rgb(0 0 0 / 0.25));
}

.animate-snowflake {
  transform: translateX(-10px) rotate3d(0, 1, 0, 0deg);
  animation: sideToSide 4s ease-in-out infinite;
}

.animate-snowflake-reverse {
  transform: translateX(-10px) rotate3d(0, 1, 0, 180deg);
  animation: sideToSide 4s ease-in-out infinite reverse;
}

@keyframes sideToSide {
  0% {
    transform: translateX(-10px) rotate3d(0, 1, 0, 0deg);
  }

  50% {
    transform: translateX(10px) rotate3d(0.2, 1, 0, 40deg);
  }

  100% {
    transform: translateX(-10px) rotate3d(0, 1, 0, 180deg);
  }
}

.tag {
  @apply text-white;
}

.tag-primary {
  background: linear-gradient(264.92deg, #76bcd9 7.35%, #a4d1e9 93.42%);
}

.tag-secondary {
  @apply uppercase bg-black/40;
}

.faq-body a {
  @apply underline mouse-hover:no-underline;
}
